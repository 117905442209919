$ = window.jQuery;
const init = function () {
    // Get all the menu and more menu items
    var $menuItems = $(".menu__item");
    var menuListWidth = 0;
    var menuLogoWidth = 187;
    if ($(".menu__logo").width() !== 0) {
        menuLogoWidth = $(".menu__logo").width();
    }
    var acsMenuArray = [];
    var menuItem = {};
    var pagePath = window.location.pathname;

    // Highlight section to match url
    var $menuLinks = $(".menu__link");
    $menuLinks.each(function () {
        var menuLink = $(this);
        var menuLinkPath = menuLink.attr("href").split(".")[0];
        if (pagePath.indexOf(menuLinkPath) === 0) {
            menuLink.addClass("menu__link--active");
        }
    });

    $menuItems.each(function () {
        var $menuItem = $(this);
        var $menuItemWidth = $menuItem.width();
        var $menuNoSub = $menuItem.hasClass("menu__no-sub");

        menuListWidth = menuListWidth + $menuItemWidth;
        menuItem = {
            item: $menuItem,
            width: $menuItemWidth,
            menuListWidth: menuListWidth,
            noSubMenu: $menuNoSub,
        };
        acsMenuArray.push(menuItem);
    });

    if (window.matchMedia("(max-width:767px)").matches) {
        mobileMenus(acsMenuArray);
    }

    window.matchMedia("(max-width:767px)").addEventListener("change", function (event) {
        if (event.matches) {
            mobileMenus(acsMenuArray);
        }
    });

    function mobileMenus(acsMenuArray) {
        $(window).off("resize");
        rebuildMenu();

        function rebuildMenu() {
            var menuListHtml = "";

            $(acsMenuArray).each(function () {
                var itemAnchor = this.item.html();
                var itemClassname = "menu__item";
                if (this.noSubMenu) {
                    itemClassname += " menu__no-sub";
                }
                menuListHtml += '<li class="' + itemClassname + '">' + itemAnchor + "</li>\n";
            });

            $(".menu__list").html(menuListHtml);
        }

        // show search
        $(".hat__search-button").click(function () {
            if (!$(this).hasClass("showit")) {
                $(".menu-on").removeClass("menu-on");
                $(".showit").removeClass("showit");
                $(".hide").removeClass("hide");
                $(".menu").removeClass("account-on");
                $("body").removeClass("noscroll");
            }
            $(".hat__menu-back").removeClass("showit");
            $(".menu__logo").removeClass("hide");
            $("body").toggleClass("noscroll");
            $(".hat__search-button").toggleClass("showit");
            $("#search").toggleClass("showit");
            $(".menu").toggleClass("showit").toggleClass("search-on");
            $(".acsHeader").toggleClass("menu-on");
            $(".menu__list").addClass("hide");
        });

        // show menu
        $(".hat__menu-button").click(function () {
            if (!$(this).hasClass("showit")) {
                $(".menu-on").removeClass("menu-on");
                $(".showit").removeClass("showit");
                $(".hide").removeClass("hide");
                $(".menu").removeClass("search-on");
                $(".menu").removeClass("account-on");
                $("body").removeClass("noscroll");
            }
            $(".menu__list").removeClass("hide");
            $(".menu__join").toggleClass("showit");
            $(".hat__menu-back").removeClass("showit");
            $(".menu__logo").removeClass("hide");
            $("body").toggleClass("noscroll");
            $(".hat__menu-button").toggleClass("showit");
            $(".menu").toggleClass("showit");
        });
    }
};
export default { init };
