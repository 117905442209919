// bootstrap
import "bootstrap-sass/assets/javascripts/bootstrap/transition.js";
import "bootstrap-sass/assets/javascripts/bootstrap/affix.js";
import "bootstrap-sass/assets/javascripts/bootstrap/alert.js";
import "bootstrap-sass/assets/javascripts/bootstrap/dropdown.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tooltip.js";
import "bootstrap-sass/assets/javascripts/bootstrap/modal.js";
import "bootstrap-sass/assets/javascripts/bootstrap/button.js";
import "bootstrap-sass/assets/javascripts/bootstrap/popover.js";
import "bootstrap-sass/assets/javascripts/bootstrap/scrollspy.js";
import "bootstrap-sass/assets/javascripts/bootstrap/collapse.js";
import "bootstrap-sass/assets/javascripts/bootstrap/tab.js";

import megamenu from "../js/megamenu.js";
import search from "../js/search.js";
import backtotop from "../js/backtotop.js";
import targetedBanner from "../js/targetedBanner.js";

$(document).ready(function () {
    megamenu.init();
    search.init();
    backtotop.init();
    targetedBanner.init();
});
