$ = window.jQuery;
const init = function () {
    $('a[href="#search-full"]').on("click", function (event) {
        event.preventDefault();
        var $search = $("#search-full");
        $search.addClass("open");
        $search.find('> form > input[type="search"]').focus();
        hide_rest_of_dom("#search-full");
    });

    $("#search-full, #search-full .close").on("click keyup", function (event) {
        if ((event.target == this || event.target.className == "close" || event.keyCode == 27) && event.keyCode != 9) {
            unhide_dom();
            $("#search-full").removeClass("open");
        }
    });
    var focusable_selector = 'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

    var hide_rest_of_dom = function (modal_selector) {
        var hide = [],
            hide_i,
            tabindex,
            focusable = document.querySelectorAll(focusable_selector),
            focusable_i = focusable.length,
            modal = document.querySelector(modal_selector),
            modal_focusable = modal.querySelectorAll(focusable_selector);

        /*convert to array so we can use indexOf method*/
        modal_focusable = Array.prototype.slice.call(modal_focusable);
        /*push the container on to the array*/
        modal_focusable.push(modal);

        /*separate get attribute methods from set attribute methods*/
        while (focusable_i--) {
            /*dont hide if element is inside the modal*/
            if (modal_focusable.indexOf(focusable[focusable_i]) !== -1) {
                continue;
            }
            /*add to hide array if tabindex is not negative*/
            tabindex = parseInt(focusable[focusable_i].getAttribute("tabindex"));
            if (isNaN(tabindex)) {
                hide.push([focusable[focusable_i], "inline"]);
            } else if (tabindex >= 0) {
                hide.push([focusable[focusable_i], tabindex]);
            }
        }

        /*hide the dom elements*/
        hide_i = hide.length;
        while (hide_i--) {
            hide[hide_i][0].setAttribute("data-tabindex", hide[hide_i][1]);
            hide[hide_i][0].setAttribute("tabindex", -1);
        }
    };
    var unhide_dom = function () {
        var unhide = [],
            unhide_i,
            data_tabindex,
            hidden = document.querySelectorAll("[data-tabindex]"),
            hidden_i = hidden.length;

        /*separate the get and set attribute methods*/
        while (hidden_i--) {
            data_tabindex = hidden[hidden_i].getAttribute("data-tabindex");
            if (data_tabindex !== null) {
                unhide.push([hidden[hidden_i], data_tabindex == "inline" ? 0 : data_tabindex]);
            }
        }

        /*unhide the dom elements*/
        unhide_i = unhide.length;
        while (unhide_i--) {
            unhide[unhide_i][0].removeAttribute("data-tabindex");
            unhide[unhide_i][0].setAttribute("tabindex", unhide[unhide_i][1]);
        }
    };
};
export default { init };
