$ = window.jQuery;
const init = function () {
    var targetedBanner = document.getElementsByClassName("targetedBanner") ? document.getElementsByClassName("targetedBanner")[0] : null;
    // Don't run this script if there's no banner or we're in Author Edit Mode
    if (!targetedBanner || inEditMode) {
        return;
    }

    var userCheckerUpdater;
    var cookie_name = "hideTargetedBanner";
    var targetedBannerMember = targetedBanner.getElementsByClassName("container member")[0];
    var targetedBannerNonMember = targetedBanner.getElementsByClassName("container non-member")[0];
    var targetedBannerClose = document.getElementsByClassName("targeted-banner--close")[0];

    // If user closes banner, don't show for 90 days
    if (document.cookie == null || document.cookie.indexOf(cookie_name) == -1) {
        targetedBanner.classList.remove("disabled");
    }
    targetedBannerClose.addEventListener("click", function () {
        targetedBanner.classList.add("disabled");
        var date = new Date();
        var days = 90;
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = cookie_name + "='true'; path=/; expires=" + date.toUTCString();
    });

    // Show correct banner based on member status
    userCheckerUpdater = function () {
        if (window?.acs?.user?.loggedin && window?.acs?.user?.ismember) {
            if (targetedBannerNonMember) {
                targetedBannerNonMember.classList.add("disabled");
            }
            if (targetedBannerMember) {
                targetedBannerMember.classList.remove("disabled");
            }
        } else {
            if (targetedBannerMember) {
                targetedBannerMember.classList.add("disabled");
            }
            if (targetedBannerNonMember) {
                targetedBannerNonMember.classList.remove("disabled");
            }
        }
    };
    if(window.amplify){
        window.amplify.subscribe("sso.status.changed", function (newData) {
            userCheckerUpdater();
        });
    }else{
        console.log("window.amplify missing");
    }

    userCheckerUpdater();
};

export default { init };
