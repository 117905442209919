$ = window.jQuery;

const init = function () {

    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $("#back-to-top").fadeIn();
        } else {
            $("#back-to-top").fadeOut();
        }
    });

    // scroll body to 0px on click
    $("#back-to-top").click(function () {
        $("#back-to-top").tooltip("hide");
        $("body,html").animate(
            {
                scrollTop: 0,
            },
            800,
        );
        return false;
    });

    try {
        // in author mode the jquery UI conflicts with bootstrap because of the load order and breaks authoring somehow
        $("#back-to-top").tooltip("show");
    } catch (error) {
        console.warn(error);
    }

    // HTML position:fixed page header and in-page anchors
    let scrollto = 0;
    if (location && location.hash) {
        const _name = location.hash.slice(1);
        const _elem = document.getElementsByName(_name);
        if (_elem && _elem[0]) {
            const _offset = $(_elem[0]).offset().top;
            if (_offset > 60) {
                scrollto = _offset - 60; // minus fixed header height
            }
        }
    }

    $("html, body").animate({ scrollTop: scrollto }, 0);
};

export default { init };
